import { AppType } from '@sparky/framework/build/types/framework/TokenManagerTypes';
import { PolicyState } from '../shared/AppContext';

// import PRAUrls from '../shared/urls';
export interface RestAPI {
    [param: string]: string | number | boolean;
}

export const RequestMethods: PraMap<string> = {
    get: 'GET',
    post: 'POST',
    delete: 'DELETE',
    put: 'PUT',
    patch: 'PATCH',
    options: 'OPTIONS',
};

export enum PraAppType {
    RDP = 'rdp',
    SSH = 'ssh',
    VNC = 'vnc',
}

export interface AddOnInfo {
    license_type: string;
    license_expiration: string;
    in_grace_period: boolean;
}

export interface FetchStatus {
    status: {
        error_code?: number;
        error_message?: string;
        http_status?: number;
        http_status_message?: string;
        ok?: boolean;
        stacktrace?: string;
    };
}

export interface RestModifiers {
    mimeType?: string;
    noAuthKey?: boolean;
    accept?: string;
}

export interface FawkesCloudServicesResponse {
    mobile_users_explicit_proxy: boolean;
    mobile_users_global_protect: boolean;
    mobile_users_jupiter: boolean;
    mobile_users_agentless_access: boolean;
}

export interface PraAppDefinitionRequest {
    app_id?: string;
    type: string | AppType;
    name: string;
    description: string;
    destination_host: string;
    destination_port: number;
    username?: string;
    password?: string;
    has_password?: boolean;
    authNDomain?: string;
    host_key?: string;
    ignore_invalid_cert?: boolean;
}

export interface PraAppDefinition extends PraAppDefinitionRequest {
    app_id: string;
}

export type FawkesGroupsResponse = {
    items: [];
    status: [];
    count: number;
    result: {
        domainName: string;
        lastSyncTmp: number;
        netbios: string;
        objects: {
            cn: string;
            dn: string;
            domain: string;
            groupType: string;
            mailEnabled: boolean;
            mailNickname: string;
            member: string[];
            name: string;
            objectClass: string[];
            objectGUID: string;
            objectSid: string;
            renewedDateTime: string;
            securityEnabled: boolean;
            topoIndex: number;
            whenChanged: string;
            type: string;
        }[];
    }[];
};

type CieUser = {
    cn: string;
    dn: string;
    objectGUID: string;
    type: string;
    userPrincipalName: string;
    sAMAccountName: string;
    displayName: string;
    mail: string;
};

export type FawkesUsersResponse = {
    items: [];
    status: [];
    count: number;
    result: {
        domainName: string;
        lastSyncTmp: number;
        netbios: string;
        objects: CieUser[];
    }[];
};

export type UserPayload = { 
    cie_sAMAccountName: string;
    cie_netbios: string;
    cie_mail: string;
};

export interface PraAppsResponse extends FetchStatus {
    items: PraAppDefinition[];
}

export interface PraAppResponse extends FetchStatus {
    item: PraAppDefinition;
}

export interface PraAppTableRow extends PraAppDefinition {
    beingDelete?: boolean;
    beingTerminated?: boolean;
}




export interface PraActiveSession {
    session_id: string;
    "user_id": string,
    "app_id": string,
    "zgw_id": string,
    "context": string,
    "start_time": string,
    "last_heartbeat": string,
    "status": "active" | "inactive",
    "app_type": PraAppType,
    "app_group": string,
    "source_ip": string,
    "device": string,
    "browser": string,
    "session_type": "app" | "browser"
}

export interface PraActiveSessionResponse extends FetchStatus {
    items: PraActiveSession[];
}

export interface PraAppGroup {
    name: string;
    group_id: string;
    description: string;
    app_ids: string[];
}

export interface PraAppGroupResponse extends FetchStatus {
    items: PraAppGroup[];
}

export interface PraAppGroupRow extends PraAppGroup {
    beingDeleted: boolean;
    appNames: string[];
}

export interface PraPortalData {
    cie_tenant_id: string;
    cie_region: string;
    cie_auth_profile_id: string;
    max_sessions_per_user: number;
    cie_directory_domain: string;
    subdomain: string;
    portal_fqdn: string;
    enable_users_to_manually_define_apps: boolean;
    profile_id_for_custom_apps: string;
    use_custom_domain: boolean;
    enabled: boolean;
    max_logo_data_url_size_bytes: number;
    portal_hosted_zone: string;
    logo_data_url: string;
    portal_tab_name: string;
    edge_locations: string[];
}

export interface PraPortalDataResponse extends FetchStatus {
    item: PraPortalData;
}

export type PraPolicyActionsCmd = 'allow' | 'deny';

export interface PraPolicyActions {
    action: PraPolicyActionsCmd;
    users: UserPayload[];
    user_groups: string[];
}

export interface PraPolicy {
    rank?: number;
    name: string;
    enabled: boolean;
    description: string;
    profile_id: string;
    app_ids?: string[];
    app_group_ids?: string[];
    rules: {
        actions: PraPolicyActions[];
    };
    policy_id?: string;
}

export interface PraPolicyListResponse extends FetchStatus {
    items: PraPolicy[];
}

export interface PraPolicyRow extends PraPolicy {
    beingDeleted: boolean;
    profileName: string;
    policyState: PolicyState;
    appNames: string[];
    appGroupIds: string[];
}

export type PolicyFormValues = {
    app_ids: { value: string; app_id: string }[];
    description?: PraPolicy['description'];
    enabled?: PraPolicy['enabled'];
    name?: PraPolicy['name'];
    profile_id: { value: string; profile_id: string };
    rank?: PraPolicy['rank'];
    user_groups: { value: string }[];
    users: { user_payload: UserPayload }[];
};

export interface PolicyPayload
    extends Omit<PolicyFormValues, 'user_groups' | 'users' | 'app_ids' | 'profile_id'> {
    app_ids?: string[];
    profile_id?: string;
    rules?: {
        actions: {
            action: string;
            user_groups: string[];
            users: UserPayload[];
        }[];
    };
}

export interface PraProfile {
    name: string;
    description: string;
    rdp: {
        allow_copy: boolean;
        allow_paste: boolean;
        allow_print: boolean;
        allow_file_upload: boolean;
        allow_file_download: boolean;
    };
    ssh: {
        allow_copy: boolean;
        allow_paste: boolean;
        allow_file_transfer_by_sftp: boolean;
    };
    vnc: {
        allow_copy: boolean;
        allow_paste: boolean;
        allow_file_transfer_by_sftp: boolean;
    };
    profile_id: string;
    is_read_only: boolean;
}

export interface PraProfileListResponse extends FetchStatus {
    items: PraProfile[];
}

export interface PraProfileRow extends PraProfile {
    beingDeleted: boolean;
    readOnly?: boolean;
}

export type RadioValueType = 'enabled' | 'disabled';

export type ProfileFormValues = {
    name: PraProfile['name'];
    description: PraProfile['description'];
    rdp_allow_copy: RadioValueType;
    rdp_allow_paste: RadioValueType;
    rdp_allow_print: RadioValueType;
    rdp_allow_upload: RadioValueType;
    rdp_allow_download: RadioValueType;
    ssh_allow_copy: RadioValueType;
    ssh_allow_paste: RadioValueType;
    ssh_allow_file_transfer_by_sftp: RadioValueType;
};

export interface ProfilePayload extends Omit<PraProfile, 'is_read_only' | 'profile_id' | 'vnc'> {
    name: string;
    description: string;
    profile_id?: string;
    vnc?: {
        // vnc is not supported for now...adding it as optional
        allow_copy: boolean;
        allow_paste: boolean;
        // allow_file_transfer_by_sftp: boolean;
    };
}

export interface HookResponseData {
    (response: ZRestResponse): void;
}

export type ZRestResponse =
    // | AgentlessAppDefinition
    // | AppManagementAppResponse
    // | FawkesCertInfo
    // | FawkesCertInfoResponse
    | FawkesGroupsResponse
    | FawkesUsersResponse
    | FawkesCloudServicesResponse
    | FawkesCloudServicesResponse[]
    | FetchStatus
    | PraAppsResponse
    | PraAppResponse
    | PraPortalData
    | PraPolicyListResponse
    | PraProfileListResponse
    | PraPortalDataResponse
    | PraAppGroupResponse;

export type PraMap<Type> = {
    [name: string]: Type;
};


/*

export interface DestinationDomain {
    domain_name: string; // called destination in the figma (FQDN/ip addr)
    port: number;
    protocol: string;
    sni: string;
}

export interface AgentlessAppDefinition {
    app_name: string;
    domain_description: string;
    icon: string;
    cert_name: string;
    domain_name: string; // called URL in the figma
    domain_id?: string;
    origins: DestinationDomain[];
    cname?: string;
    port?: string;
    is_ready?: boolean;
}

export interface AgentlessTableRow extends AgentlessAppDefinition {
    beingDelete?: boolean;
}

export interface AppManagementRequest {
    application: AgentlessAppDefinition;
}

export interface AppManagementAppsResponse extends FetchStatus {
    items: AgentlessAppDefinition[];
}

export interface AppManagementAppResponse extends FetchStatus {
    item: AgentlessAppDefinition;
}

export interface FawkesCertInfo {
    '@loc': string; // "Mobile Users Container",
    '@name': string; // "jira_demotenant_com",
    '@uuid': string; // "d482ffca-c94c-4d99-959c-19279725e6f9",
    '@type': string; // "container",
    'subject-hash': string; // "53b43cad",
    'issuer-hash': string; // "e3bc0f8e",
    'not-valid-before': string; // "Dec 20 18:09:45 2023 GMT",
    issuer: string; // "/CN=Agentless root ca",
    'not-valid-after': string; // "Dec 19 18:09:45 2024 GMT",
    'common-name': string; // "jira.demotenant.com",
    'expiry-epoch': string; // "1734631785",
    ca: string; // "no",
    subject: string; // example: "/CN=jira.demotenant.com",
    'public-key': string;
    algorithm: string; // "RSA",
    'private-key': string;
    'common-name-int': string; // "jira.demotenant.com",
    'subject-int': string; // "CN = jira.demotenant.com"
}

export interface FawkesCertInfoResponse {
    'additional-info': string[];
    certificates: FawkesCertInfo[];
    ok: boolean;
    sslDecrypt: {};
    sslDecryptAdditionalInfo: [];
}
*/